// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import { GridPageWhoWeAre } from '../../components/gridPageWhoWeAre';
import Accordion from '../../components/Accordion';
import { AccordionContainer } from '../radioterapia';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 0 0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  position: relative;
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '0 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '0px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: ${props =>
      props.displayFirstChild ? props.displayFirstChild : 'flex'};
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }
  @media ${device.mobile} {
    padding-bottom: ${props => props.notPaddingTop && '20px'};
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / span 12'} isCDIUnits>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'ckvs3887kb6gt0b69lqij5gnc', true)}
          breadCrumb={getMarkdown(page, 'ckvs1jvdcax3b0c79oqx7v5b9', true)}
          asset={getMarkdown(page, 'ckvs3887kb6gt0b69lqij5gnc', false, true)}
          isOncologia
          boxContentHeightDesk="27rem"
          boxContentPaddingBottomDesk="13rem"
          boxInfoHeightLap="20rem"
          boxInfoHeightMobile="25.3125rem"
          containerMarginBottomTab="1.875rem"
          containerMarginBottomMob="0"
          boxInfoTextWidthDesk="33.125rem"
          boxInfoPaddingLeftEmDesk="2rem"
          boxInfoParagraphImgPaddingRMobile320="0.5rem"
          boxInfoPaddingRImgDesk2="0.625rem"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          boxInfoParagraphImgLastTransformDesk="translate(0, 30%)"
          boxInfoPFontWeightNormal
          boxInfoTagEmDisplay="block"
          titleWidthDesk="100%"
          titleWidthMob="10rem"
          titleWidthMob320="50%"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxContentFlexDirectionLap="unset"
          boxContentFlexDirectionTab="column-reverse"
          marginBottomTitleDesk="0rem"
          widthImgMarketingLap="25rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingMobile="12.313rem"
          positionTopImageMarketingMobile="-0.5rem"
          overflowContainerMobile="hidden"
          positionLeftImageMarketingMobile="0.75rem"
          positionLeftImageMarketingMobile320="1.725rem"
          positionImageMarketingDesk="2rem"
          marginTopBoxInfoLap="0"
          marginTopBoxInfoTab="-16rem"
          marginTopBoxInfoMob="-9rem"
          marginBottomBoxInfoMob="-3.125rem"
          boxImagesImgOuterWrapperWidth="unset"
          boxInfoLinkAsButton
          boxInfoTextWidthMobile="270px"
          boxInfoTextWidthMobile425="90%"
          widthButtonLink="270px"
          heightButtonLink="56px"
          isEnsinoEPesquisa
          paddingTopTextBoxInfo="1.2rem"
          boxInfoImgChild4Transform="translate(0, 80%)"
          boxInfoLinkAsButtonMarginDesktop="1.25rem 0 3.125rem 0"
          boxInfoLinkAsButtonMarginLaptop="0"
          boxInfoHasNoPaddingMob="0"
          boxInformationWidthTablet="23rem"
          boxInformationWidthMobile="100%"
          boxContentFlexAlignItems="flex-start"
        />
      </Part>
      {/* <Part gridColumn={'2 / -2'} notPaddingTop paddingTop paddingBottom>
        <GridPageWhoWeAre // Sobre
          classNamesTitle="sobre-ensino-e-pesquisa"
          markdown={getMarkdown(page, 'ckvshnr54evok0b284x5ltgmi', true)}
          fragments={getMarkdown(page, 'ckvshnr54evok0b284x5ltgmi')}
          resumed
          gridCostumized
          isEnsinoEPesquisa
          imgAdjustHeight="658px"
          textWidthDesk="100%"
          imgAdjustWidthmobile="100%"
          // marginbottomImgMobile="30px"
          marginGrindContainer="70px"
        />
      </Part> */}
      <Part gridColumn={'2 / -2'} notPadding notLeft>
        <AccordionContainer>
          <div>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvshnr54evok0b284x5ltgmi',
                true
              )}
              isFormatted
              isEnsinoEPesquisa
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvthe30gkdmf0b283bj2fvtw',
                true
              )}
              isFormatted
              isEnsinoEPesquisa
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvthw7sgkh2s0c73n1llbs0q',
                true
              )}
              isFormatted
              isEnsinoEPesquisa
              marginBottomNossaEquipe
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvtrzbjs10rc0a25pmp6h2y7',
                true
              )}
              isDisplay="unset"
              isFormatted
              isEnsinoEPesquisaAndNossaEquipe
              infoNumbers
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvtu60401xb00a25shwh1dyj',
                true
              )}
              isFormatted
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvtzsz7k48l20b65z7poywxf',
                true
              )}
              isFormatted
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'ckvtzv3lk4eyt0a3304uurhtj',
                true
              )}
              isFormatted
              noBorderBottomLastElement
            />
          </div>
        </AccordionContainer>
      </Part>
    </GridContainer>
  );
};

const EnsinoEPesquisa = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'ckvs3887kb6gt0b69lqij5gnc', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckvs1gf54awe90c796n98a0dq" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <EnsinoEPesquisa page={response.gcms.site.pages} />;
      }}
    />
  );
};
